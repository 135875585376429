<template>
    <b-row>

      <!-- User Info: Left col -->
      <b-col
        cols="12"
      >
        <!-- User Avatar & Action Buttons -->
            <div class="mb-1 d-flex justify-content-between w-100">
              <div>
                <h4 class="mb-0">
                  {{ userData.name }}
                </h4>
                <span class="card-text">{{ userData.email }} | último login: {{ userData.last_login }}</span>
              </div>
              <div class="d-flex bg-primary status-bar" :class="{statActive: userData.status === 'active', statSus: userData.status === 'suspended'}">
                <h5 class="mr-2 mb-0 text-white font-weight-bold">
                  Status:
                </h5>
                <h5 v-if="userData.status_label" class="text-white mb-0">
                  <span>
                    {{ userData.status_label }}
                  </span>
                </h5>
              </div>
            </div>
            <div class="d-flex flex-wrap mb-2">
              <b-button
                v-if="userData.status === 'in_progress'"
                variant="success"
                class="mr-1"
                @click="approveUser(userData.id)"
              >
                Aprovar
              </b-button>
              <b-button
                variant="outline-danger"
                @click="withHtml(userData.id)"
              >
                Deletar
              </b-button>
              <b-button
                variant="warning"
                v-if="userData.status === 'active'"
                class="ml-1"
                @click="suspendUser(userData.id)"
              >
                Suspender
              </b-button>
              <b-button
                variant="success"
                v-if="userData.status === 'suspended'"
                class="ml-1"
                @click="activeUser(userData.id)"
              >
                Ativar
              </b-button>
              <!-- <b-button variant="primary" class="ml-1" :to="{ name: 'apps-users-edit', params: { id: userData.id } }">
                <feather-icon
                  icon="Edit3Icon"
                  size="16"
                />
              </b-button> -->
              <b-button variant="primary" class="ml-1" @click="superLogin(userData.id)">
                <feather-icon
                  icon="KeyIcon"
                  size="16"
                />
              </b-button>
              <b-button variant="success" class="ml-1" :href="'https://wa.me/55'+userData.mobile_phone" target="_blank">
                <ion-icon name="logo-whatsapp"></ion-icon>
              </b-button>
            </div>
      </b-col>
    </b-row>
</template>

<script>
import {
  BButton, BRow, BCol,
} from 'bootstrap-vue'/* BAvatar */
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { avatarText } from '@core/utils/filter'
import axios from '@axios'
import useUsersList from '../users-list/useUsersList'

export default {
  components: {
    BButton, BRow, BCol,
  }, /* BAvatar, */
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { resolveUserRoleVariant } = useUsersList()
    return {
      avatarText,
      resolveUserRoleVariant,
    }
  },
  methods: {
    superLogin(id) {
      axios.post(`/admin/users/login/${id}`).then(res => {
        window.open(`${process.env.VUE_APP_CLIENT_URL}/super/${res.data.token}`, '_blank')
      }).catch(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Usuario inválido',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
      })
    },
    approveUser(id) {
      axios.post(`/admin/users/${id}/active`).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Usuario aprovado com sucesso',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.$emit('refresh')
      })
    },
    withHtml(id) {
      this.$swal({
        title: '<span class="font-weight-bolder">Deletar usuário</span>',
        icon: 'info',
        html:
          'Você não pode desfazer essa ação... ',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Deletar!',
        cancelButtonText: 'Voltar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.deleteUser(id)
        }
      })
    },
    suspendUser(id) {
      axios.put(`/admin/users/status/${id}`, { status: 'suspended' }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Usuario suspenso com sucesso',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        // this.$router.replace('/')
        this.$emit('refresh')
      })
    },
    activeUser(id) {
      axios.put(`/admin/users/status/${id}`, { status: 'active' }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Usuario suspenso com sucesso',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        /* this.$router.replace('/') */
        this.$emit('refresh')
      })
    },
    deleteUser(id) {
      axios.delete(`/admin/users/${id}`, {}).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Usuario excluído com sucesso',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.$router.replace('/')
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.status-bar{
  padding: 8px 25px;
  border-radius: 20px;
  align-self: center;
}
.status-bar.statActive{
  background-color: #28c76f !important;
}
.icon-status{
  border-radius: 20px;
  background: #fff;
  margin: 4px;
}
</style>
