<template>
  <div>
    <CoolLightBox
      :items="[src]"
      :index="index"
      @close="index = null" />
    <!-- <video v-if="src" width="100%" height="400" controls>
      <source :src="src" type="video/mp4">
      Your browser does not support the video tag.
    </video> -->
    <img @click="index = 0" v-if="src" :src="src" class="img-fluid click" alt="">
  </div>
</template>

<script>
// import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
// import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import axios from '@axios'
import CoolLightBox from 'vue-cool-lightbox'

export default {
  components: {
    CoolLightBox,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      src: null,
      index: null,
    }
  },
  mounted() {
    axios.get(this.userData.confirmation_media, { responseType: 'arraybuffer' }).then(response => {
      const blob = new Blob([response.data], { type: 'image/*' })
      this.src = window.URL.createObjectURL(blob)

      // window.open(url) // Mostly the same, I was just experimenting with different approaches, tried link.click, iframe and other solutions

      /* FileDownload(response, 'Frente.jfif') */
    })
  },
}
</script>

<style lang="scss" scoped>
img{
  height: 400px;
  object-fit: cover;
}
.click:hover{
  cursor: pointer;
  border: 2px solid #D0065E;
}
</style>
