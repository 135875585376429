<template>
  <div>

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="userData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching user data
      </h4>
      <div class="alert-body">
        No user found with this user id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-users-list'}"
        >
          User List
        </b-link>
        for other users.
      </div>
    </b-alert>

    <template v-if="userData">
      <CoolLightBox
        v-if="userData.ads && userData.ads.photos"
        :items="imgsArray(userData.ads.photos.data)"
        :index="index"
        @close="index = null" />
      <CoolLightBox
        v-if="userData.ads && userData.ads.photos"
        :items="[userData.ads.cover]"
        :index="cover"
        @close="cover = null" />
      <!-- First Row -->
      <!-- <b-row>
        <b-col
          cols="12"
        >
          <user-view-user-info-card :user-data="userData" />
        </b-col>
      </b-row> -->
      <b-tabs
        pills
        align="left"
      >
        <b-tab
          title="Dados do usuário"
          active
        >
          <b-row>
            <b-col
              cols="12"
            >
              <b-card no-body>
                <b-card-header header-border-variant="gray" class="d-flex justify-content-between align-items-center pt-75 pb-25">
                  <h3 class="mb-0">
                    Dados do usuário
                  </h3>
                </b-card-header>
                <hr>
                <b-card-body>
                  <div class="d-flex">
                    <img v-if="userData.ads && userData.ads.cover" @click="access(userData.ads.genre.slug, userData.ads.city.state.abbreviation, userData.ads.city.slug, userData.ads.slug, userData.ads)" class="img-fluid user-thumb mb-2 mr-2" :src="userData.ads.cover">
                    <img v-else class="img-fluid user-thumb mb-2 mr-2" :src="require('@/assets/images/default.jpg')">
                    <div class="w-100">
                      <user-view-user-info-card :user-data="userData" @refresh="refreshUser" />
                    </div>
                  </div>
                  <user-edit-tab-account
                    @sendSubmit="updateUserData"
                    :errors="errors"
                    :user-data="userData"
                  />
                </b-card-body>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Documentos">
          <b-row>
            <b-col
              cols="12"
              lg="6"
            >
              <b-card title="Documentos">
                <user-documents v-if="userData.documents && userData.documents.data[0]" :user-data="userData" />
                <p v-else>Usuário sem foto dos documentos</p>
              </b-card>
            </b-col>
            <b-col
              cols="12"
              lg="6"
            >
              <b-card title="Selfie">
                <user-video v-if="userData.documents && userData.documents.data[0]" :user-data="userData" />
                <p v-else>Usuário sem selfie de confirmação</p>
              </b-card>
            </b-col>
          </b-row>
        </b-tab>
        <b-tab title="Anúncio">
            <b-card v-if="userData.ads" title="Anúncio">
              <b-row>
                <b-col
                  cols="12"
                  lg="6"
                >
                  <p><strong>Título</strong></p>
                  <p>{{userData.ads.title}}</p>
                  <p><strong>Descrição</strong></p>
                  <div v-html="userData.ads.description" />
                  <p><strong>Anúncio criado em:</strong></p>
                  <div v-html="userData.ads.created_at" />
                </b-col>
                <b-col
                  cols="12"
                  lg="6"
                >
                  <p><strong>Status do anúncio</strong></p>
                  <div class="d-flex">
                    <p>{{(userData.ads.is_published === 1) ? 'Publicado' : 'Não Publicado'}}</p>
                    <b-link v-if="userData.ads.is_published === 1" variant="primary" class="ml-1" target="_blank" @click.prevent="access(userData.ads.genre.slug, userData.ads.city.state.abbreviation, userData.ads.city.slug, userData.ads.slug, userData.ads)">
                      <feather-icon
                        icon="LogInIcon"
                        size="16"
                      />
                      Visualizar
                    </b-link>
                  </div>
                  <div class="d-flex">
                    <p class="mt-1 mr-1"><strong>Plano Atual</strong></p>
                    <div>
                      <b-button
                        variant="primary"
                        class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                        @click="planEdit = !planEdit"
                      >
                        <feather-icon
                          icon="Edit3Icon"
                          size="16"
                        />
                      </b-button>
                    </div>
                  </div>
                  <v-select
                    v-if="planEdit"
                    v-model="newPlan"
                    :options="plansOptions"
                    label="label"
                    class="assignee-selector mb-1"
                    input-id="assignee"
                    :selectable="item => item.value"
                  >
                    <template #option="{ label, value }">
                      <span v-if="value"> {{ label }}</span>
                      <span class="select-header" v-else> {{ label }} </span>
                    </template>
                  </v-select>
                  <p v-else>{{(userData.ads.plan) ? daysLeft(userData.ads.plan_expire_at) : ''}} - {{(userData.ads.plan) ? userData.ads.plan : 'Gratuito'}}</p>
                  <p><strong>Cidade</strong></p>
                  <p v-if="userData.ads.city">{{userData.ads.city.name}} - {{userData.ads.city.state.name}}</p>
                  <p><strong>Cidade Próxima</strong></p>
                  <p v-if="userData.ads.nearby_city">{{userData.ads.nearby_city.state.name}} - {{userData.ads.nearby_city.name}}</p>
                </b-col>
                <b-col
                  cols="12"
                >
                  <b-row>
                    <b-col
                      cols="12"
                      v-if="!userData.ads.cover && userData.ads.photos.data && userData.ads.photos.data.length === 0"
                    >
                      <p>Sem imagens cadastradas no anúncio...</p>
                    </b-col>
                    <b-col
                      cols="6"
                      md="3"
                    >
                      <img v-if="userData.ads.cover" @click="cover = 0" class="img-fluid click mb-2" :src="userData.ads.cover">
                    </b-col>
                    <b-col
                      cols="6"
                      md="3"
                      v-for="(item, i) in imgsArray(userData.ads.photos.data)" :key="'imgs_'+i"
                    >
                      <img @click="index = i" class="img-fluid click mb-2" :src="item">
                    </b-col>
                    <b-col
                      cols="6"
                      md="3"
                      v-for="(item, i) in userData.ads.videos.data" :key="'imgs_'+i"
                    >
                      <video class="img-fluid click mb-2" controls>
                        <source :src="item.url" type="video/mp4">
                        Your browser does not support the video tag.
                      </video>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-card>
            <b-card v-else>
              <p>Nenhum anúncio criado ainda</p>
            </b-card>
        </b-tab>
      </b-tabs>

      <!-- <invoice-list /> -->
    </template>

  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import { ref, onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import {
  BRow, BCol, BAlert, BLink, BTabs, BTab, BCard, BCardHeader, BCardBody, BButton,
} from 'bootstrap-vue'
// import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from '@axios'
import dayjs from 'dayjs'
import userStoreModule from '../userStoreModule'
import UserViewUserInfoCard from './UserViewUserInfoCard.vue'
// import UserViewUserPlanCard from './UserViewUserPlanCard.vue'
import UserVideo from './UserVideo.vue'
import UserDocuments from './UserDocuments.vue'
import UserEditTabAccount from './UserEditTabAccount.vue'

export default {
  components: {
    BRow,
    BCol,
    BAlert,
    BLink,
    BTabs,
    BTab,
    BCard,
    BButton,
    BCardHeader,
    BCardBody,
    CoolLightBox,
    UserEditTabAccount,
    vSelect,

    // Local Components
    UserViewUserInfoCard,
    // UserViewUserPlanCard,
    UserVideo,
    UserDocuments,

    // InvoiceList,
  },
  data() {
    return {
      index: null,
      cover: null,
      errors: {},
      planEdit: false,
      newPlan: null,
    }
  },
  setup() {
    const userData = ref(null)
    const plansOptions = []

    const USER_APP_STORE_MODULE_NAME = 'app-user'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    store.dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
      .then(response => { userData.value = response })
      .catch(error => {
        if (error.response.status === 404) {
          userData.value = undefined
        }
      })

    store.dispatch('app-user/fetchPlans')
      .then(response => {
        response.data.data.forEach(d => {
          plansOptions.push({ label: d.name, value: null })
          d.plan_details.data.forEach(p => {
            plansOptions.push({ label: `${p.days} Dias`, value: p.id, grade: d.name })
          })
        })
      })
    return {
      userData,
      plansOptions,
    }
  },
  methods: {
    access(gen, state, city, slug, ads) {
      if (ads.is_published) {
        window.open(`${process.env.VUE_APP_CLIENT_URL}/${gen}/${state}/${city}/${slug}`, '_blank')
      }
    },
    updatePlanModal() {
      this.$swal({
        title: `<span class="font-weight-bolder">Atualizar plano:  ${this.newPlan.grade} - ${this.newPlan.label}</span>`,
        icon: 'info',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Atualizar!',
        cancelButtonText: 'Voltar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          axios.post(`/admin/orders/${this.newPlan.value}/${this.userData.ads.id}/store`, {}).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Plano atualizado com sucesso',
                icon: 'EditIcon',
                variant: 'success',
              },
            })
            this.planEdit = false
            this.refreshUser()
          })
        }
      })
    },
    daysLeft(date) {
      const today = dayjs()
      const expire = dayjs(date).add(1, 'day')
      return expire.diff(today, 'day') // 7
    },
    imgsArray(imgs) {
      const data = []
      imgs.forEach(item => {
        data.push(item.url)
      })
      return data
    },
    refreshUser() {
      store.dispatch('app-user/fetchUser', { id: router.currentRoute.params.id })
        .then(response => { this.userData = response })
        .catch(error => {
          if (error.response.status === 404) {
            this.userData = undefined
          }
        })
    },
    updateUserData(data) {
      store.dispatch('app-user/updateUser', data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Usuário editado com sucesso',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
          this.refreshUser()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro ao editar usuário',
              icon: 'EditIcon',
              variant: 'error',
            },
          })
          if (error.response) {
            this.errors = error.response.data.errors
          }
        })
    },
  },
  watch: {
    // whenever question changes, this function will run
    newPlan() {
      this.updatePlanModal()
    },
  },
}
</script>

<style lang="scss" scoped>
.click{
  width: 100%;
  height: 250px;
  object-fit: cover;
}
.click:hover{
  cursor: pointer;
  border: 2px solid #D0065E;
}
.user-thumb{
  cursor: pointer;
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 10px
}
.select-header{
  font-weight: 900;
  font-size: 17px;
  color: #000;
  opacity: 1 !important;
}
</style>
