<template>
  <div>
    <CoolLightBox
      :items="[src1, src2]"
      :index="index"
      @close="index = null" />
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <img @click="index = 0" class="img-fluid click" :src="src1">
      </b-col>
      <b-col
        cols="12"
        md="6"
      >
        <img @click="index = 1" class="img-fluid click" :src="src2">
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'
import {
  BRow, BCol,
} from 'bootstrap-vue'/* BImg, BMedia, BAvatar, BButton, */
// import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
// import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
// import FileDownload from 'js-file-download'
import axios from '@axios'

export default {
  components: {
    // BCard,
    BCol,
    BRow,
    CoolLightBox,
    // BButton,
    // BImg,
    // BMedia,
    // BAvatar,
    // AppTimeline,
    // AppTimelineItem,
  },
  props: {
    userData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      src1: '',
      src2: '',
      index: null,
    }
  },
  mounted() {
    axios.get(this.userData.documents.data[this.userData.documents.data.length - 1].path, { responseType: 'arraybuffer' }).then(response => {
      const blob = new Blob([response.data], { type: 'image/png' })
      this.src1 = window.URL.createObjectURL(blob)
    })
    axios.get(this.userData.documents.data[this.userData.documents.data.length - 2].path, { responseType: 'arraybuffer' }).then(response => {
      const blob = new Blob([response.data], { type: 'image/png' })
      this.src2 = window.URL.createObjectURL(blob)
    })
  },
}
</script>

<style lang="scss" scoped>
img{
  height: 400px;
  object-fit: cover;
}
.click:hover{
  cursor: pointer;
  border: 2px solid #D0065E;
}
</style>
